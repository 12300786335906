import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { LicenseManager } from "ag-grid-enterprise";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Package from "../package.json";
//import './index.css';
import App from "./App";
import configureAppStore from "./store/configureStore";

window.user = {};

// Log Application and Version to Console
console.log(`Application: ${Package.name}`);
console.log(`Version: ${Package.version}`);

// Set AG Grid License Key
LicenseManager.setLicenseKey(
  "CompanyName=Alliance Safety Council,LicensedApplication=Pyvot Track,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-007286,ExpiryDate=10_March_2021_[v2]_MTYxNTMzNDQwMDAwMA==f4537bab8d8473bd57010d4e908ade27"
);

const store = configureAppStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
      <Switch>
        {/* path with no # to extract query param */}
        <Route path="/auth" render={() => {
          const urlParams = new URLSearchParams(window.location.search);
            const value = urlParams.get('code');
            console.log(value)
            const state = {
                code: value
              }
              return <Redirect to={{
                to: "/",
                state: state
            }} />
        }} />
        {/* rest of app using hash router */}
        <Route path="/" render={(props) => <App authCode={props.location.state} />} />
      </Switch>
    </BrowserRouter>
    </Provider>
  </React.StrictMode>
  ,
  document.getElementById("root")
);
