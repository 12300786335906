import { createSlice } from "@reduxjs/toolkit";
import { API_CALL_BEGAN } from "../api";
import { applicationListURL } from "../../AppVars";

const slice = createSlice({
  name: "applicationList",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    applicationList_REQUESTED: (applicationList, action) => {
      applicationList.loading = true;
    },

    applicationList_RECEIVED: (applicationList, action) => {
      applicationList.list = action.payload.applications;
      applicationList.loading = false;
      applicationList.lastFetch = Date.now();
    },

    applicationList_REQUEST_FAILED: (applicationList, action) => {
      applicationList.loading = false;
    },
  },
});

const {
  applicationList_RECEIVED,
  applicationList_REQUESTED,
  applicationList_REQUEST_FAILED,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadapplicationList = () => (dispatch, getState) => {
  dispatch(
    API_CALL_BEGAN({
      url: applicationListURL,
      method: "get",
      data: {},
      onStart: applicationList_REQUESTED.type,
      onSuccess: applicationList_RECEIVED.type,
      onError: applicationList_REQUEST_FAILED.type,
    })
  );
};
