// API Endpoints //

export const studentURL = "/students";
export const applicationListURL =
  "https://sbkvv58id1.execute-api.us-east-1.amazonaws.com/dev/applicationlist";

// Base URL //

export const iLevelBaseURL = "https://ilevel.alliancesafetycouncil.org";
console.log('AppVars.js - ' + window.location.origin );
export const local = window.location.origin; //"http://localhost:3001";
export const iLevelApiProxiURL = 'https://api.dev.readysafe.org';//"http://localhost:3000";

//AWS Cogntio URLs
export const AWS_COGNITO_CONFIG = {
  Region: 'us-east-1',
  UserPoolId: 'us-east-1_WVH9CeXZE',
  AppClientId: '2eaeo9gqli32pps93dr6cs3ent',
  MandatorySignIn: false,
  AuthenticationFlowType: 'AUTHORIZATION_CODE',
  Oauth: {
    Domain:'https://ilevel.auth.us-east-1.amazoncognito.com',   //cognito domain
    Scope: ['openid', 'profile'],                                          //cognito scopes
    RedirectSignIn: `${local}/#/dashboard`,        //redirectURI
    RedirectSignOut: 'https://alliancesafetycouncil.org',       //cognito redirect upon signout
    ResponseType: 'code'                                        //'token' or 'code' - Note: no refresh token given when using code.
  }
};

// iLevel Links //

export const myProfileExtension = `${iLevelBaseURL}/SCUserProfile.aspx`;
export const classRegistration = `${iLevelBaseURL}/Registration/Online/SCRegistration.aspx`;
export const cancelRegistration = `${iLevelBaseURL}/ScRegiChangeDialog.aspx`;
export const individualTrainingHistory = `${iLevelBaseURL}/SCStudentHistory.aspx`;
export const receipts = `${iLevelBaseURL}/SCReport_Receipts.aspx`;
export const GoToAlliance = "";

// Utility //

export const minutesCacheIsValid = 10;
