import * as actions from "../api";
import { iLevelApiProxiURL } from "../../AppVars";
import { attachParamsToURL } from "../../library/Utilities";
import api from "../../network/api";
import axios from 'axios';
import { AWS_COGNITO_CONFIG } from '../../AppVars.js';

export const apiService = ({ dispatch }) => (next) => async (action) => {
  if (action.type !== actions.API_CALL_BEGAN.type) return next(action);
  const { url, method, data, onStart, onSuccess, onError } = action.payload;
  if (onStart) {
    dispatch({ type: onStart });
  }
  next(action);

  try {
    console.log('middleware/api.js - url', url);
    console.log('middleware/api.js - data', data);

    /*const EndpointURL = iLevelApiProxiURL + url;
    let myURL = EndpointURL;
    let response;
    if (method === "get") {
      myURL = attachParamsToURL(EndpointURL, data);
      response = await api[method](myURL);
    } else {
      response = await api[method](myURL, data);
    }
    console.log(response);
    dispatch(actions.API_CALL_SUCCESS(response.data));
    if (onSuccess) {
      dispatch({ type: onSuccess, payload: response });
    }*****/

    let queryString = '',
        response;


    console.log('middleware/api.js - queryString', queryString);

    /*const response = await axios.request({
      baseURL: iLevelApiProxiURL,
      url: `/students?${queryString}`,
      method: 'GET'
    });*/

    if(method === 'get'){
      for (const [key, value] of Object.entries(data)) {
        queryString = queryString + `${key}=${value}&`;
      }
      queryString = queryString.substr(0, queryString.length-1);

      response = await api[method](`${url}?${queryString}`);
      console.log("Student API Response", response);
      //response.data = JSON.parse(response.body);

    }



    dispatch(actions.API_CALL_SUCCESS(response.data));

    if (onSuccess)
      dispatch({ type: onSuccess, payload: response });


  }catch (error) {
    console.log('API ERROR', error);
    dispatch(actions.API_CALL_FAILED(error));
    if (onError)
      dispatch({ type: onError, payload: error.message });
    }
}

export const AuthService = ({dispatch}) => next => async action => {
  if (action.type !== actions.AUTH_CALL_BEGAN.type) return next(action);

  const { authCode, authRedirectUri, authStart, authSuccess, authFailure} = action.payload;

  if(authStart)
    dispatch({type: authStart});
  next(action);

  try{
    //grant_type=authorization_code&client_id=7cs2j24c5t4ppsmehi37ive2l8&code=4243ca3b-8ecc-4c7f-ae00-012e26835659&redirect_uri=http%3a%2f%2flocalhost%3a53678%2fSCLandingPage.aspx
    const response = await axios.request({
      baseURL: AWS_COGNITO_CONFIG.Oauth.Domain,
      url: '/oauth2/token',
      method: 'POST',
      data: `grant_type=authorization_code&code=${authCode}&client_id=${AWS_COGNITO_CONFIG.AppClientId}&redirect_uri=${encodeURIComponent(authRedirectUri)}`
    });
    dispatch(actions.AUTH_CALL_SUCCESS(response.data));
    if(authSuccess) dispatch({type: authSuccess, payload: response.data });
  }catch(err){
    dispatch(actions.AUTH_CALL_FAILURE(err.message));
    if(authFailure)
      dispatch({type: authFailure, payload: err.message});
  }
};
