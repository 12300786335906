import { createSlice } from "@reduxjs/toolkit";
import { API_CALL_BEGAN } from "../api";
import { isCacheValid } from "../../library/Utilities";
import { studentURL, iLevelApiProxiURL } from "../../AppVars";

const slice = createSlice({
  name: "students",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    STUDENTS_REQUESTED: (students, action) => {
      students.loading = true;
    },

    STUDENTS_RECEIVED: (students, action) => {
      students.list = action.payload;
      students.loading = false;
      students.lastFetch = Date.now();
    },

    STUDENTS_REQUEST_FAILED: (students, action) => {
      students.loading = false;
    },

    STUDENT_ADDED: (students, action) => {
      students.list.push(
        action.payload
        /* {
        Firstname: action.payload.Firstname,
        Lastname: action.payload.Lastname,
        EmailAddress: action.payload.EmailAddress,
        Phone: action.payload.Phone,
        DateOfBirth: action.payload.DateOfBirth
      } */
      );
    },
  },
});

const { STUDENTS_RECEIVED, STUDENT_ADDED, STUDENTS_REQUESTED, STUDENTS_REQUEST_FAILED } = slice.actions;
export default slice.reducer;

// Action Creators

export const loadStudents = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.students;
  if (lastFetch && isCacheValid(lastFetch)) return;

  dispatch(
    API_CALL_BEGAN({
      url: `${iLevelApiProxiURL}/students`,
      method: "get",
      data: { employerId: 7753, pageSize: 2000 },
      onStart: STUDENTS_REQUESTED.type,
      onSuccess: STUDENTS_RECEIVED.type,
      onError: STUDENTS_REQUEST_FAILED.type,
    })
  );
};

export const addStudent = (student) =>
  API_CALL_BEGAN({
    url: studentURL,
    method: "post",
    data: student,
    onSuccess: STUDENT_ADDED.type,
  });
