import axios from "axios";
//import isArray from 'lodash/isArray';
//import get from 'lodash/get';
//import { EndpointURL } from "../AppVars";

//const baseURL = EndpointURL;

require("dotenv").config();

let config = {
  // TODO: RETRIEVE BASE URL FROM .ENV
  //baseURL: baseURL,
  //paramsSerializer: qs.parse(window.location.search.substr(1)), //params => qs.stringify(params, {allowDots: true}),
  timeout: 0,
};

export const instance = axios.create(config);

const api = {
  async get(url, params, config = {}) {
    const { data } = await instance.get(url, { ...config }, params);
    return data;
  },
  async getStream(url, params, config = {}) {
    config = {
      responseType: "blob",
      ...config,
    };
    return await instance.get(url, { ...config, params });
  },
  async post(url, payload, config = {}) {
    return await instance.post(url, payload, { ...config });
  },
  async put(url, payload, config = {}) {
    const { data } = await instance.put(url, payload, { ...config });
    return data;
  },
  async patch(url, payload, config = {}) {
    const { data } = await instance.patch(url, payload, { ...config });
    return data;
  },
  async delete(url, params, config = {}) {
    const { data } = await instance.delete(url, { ...config, params });
    return data;
  },
};
const applyAuthHeader = async (config) => {
  // TODO: GET TOKEN DYNAMICALLY
  // const token = await GetToken();
  //config.headers['Authorization'] = `Bearer ${token}`;
  //config.headers['Access-Control-Allow-Origin'] = "*";
  //config.headers['Content-Type'] = "'application/json'";
  //config.headers['Authorization'] =
  //  'yJhbGciOiJSUzI1NiIsImtpZCI6IjI1ZDZmNjg0ZTA2NGY1MTg1MzYyN2NiZWZiZDVmM2M4IiwidHlwIjoiSldUIn0.eyJuYmYiOjE1OTQ0MTIzOTQsImV4cCI6MTU5NDQxMjk5NCwiaXNzIjoiaHR0cHM6Ly9hdXRoZGV2LmlsZXZlbC5vcmciLCJhdWQiOlsiaHR0cHM6Ly9hdXRoZGV2LmlsZXZlbC5vcmcvcmVzb3VyY2VzIiwiaUxFVkVMX0FQSSJdLCJjbGllbnRfaWQiOiIxOjM6YmY5ZjEzMGEtZTZlMy00YzUzLWIyNzctYTNiOWE2MDk3MTIxIiwic3ViIjoiMzU0ODExIiwiYXV0aF90aW1lIjoxNTk0NDEyMzk0LCJpZHAiOiJsb2NhbCIsImVtYWlsIjoiZ2F0ZUAxY2hlY2suY29tIiwidXNlcm5hbWUiOiJJUFJHQyIsInJvbGUiOiJVc2VyIiwiSW50ZXJuZXRVc2VyUGFzc3dvcmRTYWx0IjoiIiwic2NvcGUiOlsiYWRkcmVzcyIsIkNvbXBhbnlJZCIsIkNvbXBhbnlOYW1lIiwiQ3VycmljdWx1bUlkIiwiQ3VycmljdWx1bU5hbWUiLCJlbWFpbCIsIm9wZW5pZCIsInBob25lIiwicHJvZmlsZSIsImlMRVZFTF9BUEkiLCJvZmZsaW5lX2FjY2VzcyJdLCJhbXIiOlsiY3VzdG9tIl19.B5hci95eDIazS8iuodNwxjvVb0Y1bYTaccd1OWPPtBedetVFpm7qREqcCD2NBJB_CkwpJkIqI7BwUM6rzTUu0YRcXPVuaJ-YOZrh16Svhpx-kl1Aqfv7VSRBLwhW9Sg_lQmMYm4hpJ0Y_JPKZDtAC-RCmGpiz3jZxt6mipJ8jBRuYsuUz8l8AN5e_IDGoM0MVycjEZqk0IM12uIJKZ5na15e8MO9VmEwz9aEfyUBqAqUF3O8GfQefSrKlca0a37-Jamj166Ce_5RcYTcxCPiiVKujJ9uqAXIrSP6VkihSsfFK3u0TmMGWfyyqqeyRA2LAXTcDFIBHUzXYsakSmGztA';
  //config.headers['Authorization'] = config.paramsSerializer.param1;

  let ReadySafeUserObj = JSON.parse(localStorage.getItem("ReadySafeUserObj"))
  config.headers["authorization"] = ReadySafeUserObj.idToken;
  config.headers["tenantid"] = 1;
  config.headers["brandid"] = 1;
  config.headers["applicationid"] = 3;
  config.headers["environmentid"] = "2ee353e6-6028-4b54-a9f6-ce539a6dec50";
  config.headers["lang"] = "en";

  return config;
};

instance.interceptors.request.use(applyAuthHeader, (error) => Promise.reject(error));

//const unwrap = ({ data }) => {
//  console.log(data);
// return data;
//};

export const reject = (error) => {
  return Promise.reject(error);
};

//instance.interceptors.response.use(unwrap, reject);

export const UNKNOWN_ERROR_KEY = "UNKNOWN_ERROR_KEY";

export default api;
