import moment from "moment";
import { minutesCacheIsValid } from "../AppVars";

export function debounce(func, wait) {
  let timeout;

  return function () {
    const action = () => {
      clearTimeout(timeout);
      func();
    };

    clearTimeout(timeout);
    timeout = setTimeout(action, wait);
  };
}

/**
 * Recursively traverse an object in search of the value at the end of the provided dotpath.
 *
 * @param {*} object
 * @param {*} keys a stringified dotpath e.g student.id
 */
export function getValue(object, keys) {
  if (typeof keys === "string") keys = keys.split(".");

  const value = object[keys.shift()];

  if (keys.length) {
    return getValue(value, keys);
  } else {
    return value;
  }
}

export function attachParamsToURL(url, obj) {
  console.log("url ", url, obj);
  const myUrlWithParams = new URL(url);
  for (const key in obj) {
    myUrlWithParams.searchParams.append(key, obj[key]);
  }
  return myUrlWithParams;
}

export function isCacheValid(lastFetch) {
  const diffInMinutes = moment.diff(moment(lastFetch), "minutes");
  if (diffInMinutes < minutesCacheIsValid) return true;
  else return false;
}
