import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { HashRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";
import "./App.scss";
import {AWS_COGNITO_CONFIG, local} from "./AppVars.js";
import { HandleSignIn, handleSignOut, getTokens } from "./store/auth/auth";

// Containers
const Layout = React.lazy(() => import("./containers/Layout"));

// Pages
const SignIn = React.lazy(() => import("./views/Pages/SignIn/SignIn"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword/ForgotPassword"));

//store
/*
function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}*/

const App = (props) => {
console.log('PROPS:', props);

  const dispatch = useDispatch();


  const authLoading = useSelector(state => state.authorize.loading);
  const authLoggedIn = useSelector(state => state.authorize.isLoggedIn);
  const windowlocation = useLocation();

  console.log('windowlocation', windowlocation);

  const [code, setCode] = useState(null);

  useEffect(() => {
    if(props.authCode){
      setCode(props.authCode);
    }
    let QueryString = window.location.search;
    console.log('queryString', QueryString);

    //let QueryObj = parseQuery(window.location.search);
    //console.log('QueryObj', QueryObj);

    //if(QueryObj.code)
    console.log('authCode', props.authCode);
      dispatch(HandleSignIn(props.authCode));
    //else
      //dispatch(HandleSignIn(null));
  }, []);

  ///login?                                                   append this to base domain then remaining elements can be done in any order
  //client_id=${AWS_COGNITO_CONFIG.appClientId}               client ID to the Cognito AppClient should be a 26char string
  //&response_type=${AWS_COGNITO_CONFIG.oauth.responseType}   response type can be code or token.  Note with token no refresh token is generated.  For most flows this should be code
  //&scope=${AWS_COGNITO_CONFIG.oauth.scope.join('+')}        scope is a array and to make a simple string we join with '+' which adds the '+' between elements from the array which cognito needs
  //&redirect_uri=http://localhost:53678/SCLandingPage.aspx   redirect uri where cognito will redirect back to with the auth code or token.
  let cognitoEndpoint = `${AWS_COGNITO_CONFIG.Oauth.Domain}/login?client_id=${AWS_COGNITO_CONFIG.AppClientId}&redirect_uri=${local}/auth&response_type=${AWS_COGNITO_CONFIG.Oauth.ResponseType}&scope=${AWS_COGNITO_CONFIG.Oauth.Scope.join('+')}`;



//{auth ? <Route path="/" name="Layout" component={Layout} /> : <Route component={() => {window.location = cognitoEndpoint} }
  return (
      <HashRouter>
        <React.Suspense fallback={() => "loading..."}>
          <Switch>
            {authLoading ? <div></div> :
              (authLoggedIn ? <Route path="/" name="Layout" component={Layout} /> : <Route component={() => {window.location = cognitoEndpoint}}/>)}
          </Switch>
        </React.Suspense>
      </HashRouter>
  );
};

export default App;
